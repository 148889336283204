'use strict';

import {format, register} from 'timeago.js';
import {DateTime, Settings} from 'luxon';

import Cookies from 'js-cookie';
import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.magic('showmodal', function() {
  return Cookies.get('cookieshown') === undefined;
});
Alpine.start();

if (Cookies.get('cookieshown') === undefined) {
  document.querySelector("#cookieModal").classList.remove("hidden");
}

const localeFunc = function(number, index, totalSec) {
  return [
    ['just now', 'right now'],
    ['%s seconds ago', '%s seconds from now'],
    ['1 minute ago', '1 minute from now'],
    ['%s minutes ago', '%s minutes from now'],
    ['1 hour ago', '1 hour from now'],
    ['%s hours ago', '%s hours from now'],
    ['1 day ago', '1 day from now'],
    ['%s days ago', '%s days from now'],
    ['1 week ago', '1 week from now'],
    ['%s weeks ago', '%s weeks from now'],
    ['1 month ago', '1 month from now'],
    ['%s months ago', '%s months from now'],
    ['1 year ago', '1 year from now'],
    ['%s years ago', '%s years from now'],
  ][index];
};

register('my-locale', localeFunc);

if (typeof tzid === 'undefined') {
  Settings.defaultZoneName = 'UTC';
} else {
  Settings.defaultZoneName = tzid;
}

const timeAgo = function() {
  const nodes = document.querySelectorAll('.time-ago');
  if (nodes) {
    nodes.forEach(function(el) {
      el.innerHTML = format(el.dataset.timeago, 'my-locale');
    });
  }
};

const dateTimes = function() {
  let nodes = document.querySelectorAll('.utc-d');
  if (nodes) {
    nodes.forEach(function(el) {
      el.textContent = DateTime.utc().toFormat('DD');
    });
  }

  nodes = document.querySelectorAll('.utc-hms');
  if (nodes) {
    nodes.forEach(function(el) {
      el.textContent = DateTime.utc().toFormat('HH:mm:ss');
    });
  }

  nodes = document.querySelectorAll('.local-hms');
  if (nodes) {
    nodes.forEach(function(el) {
      el.textContent = DateTime.local().toFormat('HH:mm:ss');
    });
  }
};

timeAgo();
dateTimes();

setInterval(function() {
  timeAgo();
  dateTimes();
}, 1000);

window.setTimeout(function() {
  const nodes = document.querySelectorAll('.alert');
  if (nodes) {
    nodes.forEach(function(el) {
      el.classList.add('hidden');
    });
  }
}, 4000);

let nodes = document.querySelectorAll('.result-link');
if (nodes) {
  nodes.forEach(function(el) {
    el.addEventListener('click', function(event) {
      const el = event.target.closest('[data-link]');
      if (el) {
        window.location.href = el.dataset.link;
      }
    });
  });
}

nodes = document.querySelector('#cookieAccept');
if (nodes) {
  nodes.addEventListener('click', function() {
    Cookies.set('cookieshown', '1', {expires: 365});
  });
}

nodes = document.querySelector('#cookieLearn');
if (nodes) {
  nodes.addEventListener('click', function() {
    Cookies.set('cookieshown', '1', {expires: 365});
  });
}

if (typeof mapOpts !== 'undefined') {
  switch (mapOpts.js) {
    case 'station':
      import('./station').then(function(page) {
        page.render(mapOpts);
      });
      break;

    case 'area':
      import('./area').then(function(page) {
        page.render(mapOpts);
      });
      break;

    case 'aso':
      import('./aso').then(function(page) {
        page.render(mapOpts);
      });
      break;

    case 'graphs':
      import('./graphs').then(function(page) {
        page.render(mapOpts);
      });
      break;
  }
}

